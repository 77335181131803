import React, { useState } from 'react';
import avatar from '../assets/img/avatar.jpg';
import * as contact from '../store/actions/contactMeActions';
import {connect} from 'react-redux';
import DocumentMeta from 'react-document-meta';

const meta = {
    title: 'Contact Me | Omda',
    description: 'I am Emad Aldeen Mukhtar Idris Adam software developer graduate from Al-Neelain University 2016',
    canonical: 'http://omdamukhtar.com/contact-me',
    meta: {
        charset: 'utf-8',
        name: {
            keywords: 'omda,OmdaMukhtar,omda-mukhtar,Omda-Mukhtar,omda-mukhtar',
            viewport: 'initial-scale=1.0, width=device-width',
            robots: 'index,follow',
            google: 'sitelinkssearchbox'
        },
        property: {
            'og:type': 'article',
            'og:site_name': 'omdamukhtar.com',
            'twitter:site': '@omdaemad96',
            'twitter:title': 'Omda Mukhtar'
        }
    }
};

const ContactMe = (props) => {
    const [email, setEmail] =  useState("");
    const [subject, setSubject] =  useState("");
    const [message, setMessage] =  useState("");

    const changeEmail = (e)=>{
        setEmail(e.target.value);
    }

    const changeSubject = (e)=>{
        setSubject(e.target.value);
    }

    const changeMessage = (e)=>{
        setMessage(e.target.value);
    }

    const handlForm = (e)=>{
        e.preventDefault();
        
        // window.flash('record has been created successfully!', 'info');

        props.dispatch(contact.sendEmail(subject, email, message));
        setSubject('');
        setEmail('');
        setMessage('');
    }

    return ( 
        <DocumentMeta {...meta}>
            <div className="row justify-content-center ">
                <div className="col-lg-3 col-md-4 col-sm-3">
                    <div className="text-center" >
                        <h2 className="font-weight-bold">Emad Aldeen</h2>
                        <div className="border rounded">
                            <img className="shadow rounded " src={avatar} alt="avatar" width="100%" />
                        </div>

                        <p className="text-secondary mt-2 text-justify">
                            I am a web developer and sometimes a mobile developer who is more interested in how
                            to write clean codes and
                            software architectures. Very loving to programming.
                        </p>
                    </div>
                </div>
                <div className="col-lg-9 col-md-8 col-sm-9">
                    <div className="text-center ">
                        <h2 className="font-weight-bold">Contact Me</h2>
                        <div className="text-secondary jumbotron p-4">
                            <form onSubmit={handlForm}>
                                <div className="form-group row">
                                    <label>Subnject</label>
                                    <input type="text" className="form-control"
                                        placeholder="subject" value={subject} onChange={changeSubject}/>
                                </div>
                                <div className="form-group row">
                                    <label>Email</label>
                                    <input type="email" className="form-control"
                                        placeholder="email" value={email} onChange={changeEmail}/>
                                </div>
                                <div className="form-group row">
                                    <label>Subnject</label>
                                    <textarea onChange={changeMessage} className="form-control" 
                                        cols="3" rows="5"
                                        placeholder="your message"
                                        value={message}></textarea>
                                </div>
                                <div className="form-group row">
                                    <button className="btn btn-success w-100" type="submit" style={{ backgroundColor: '#17a2b8' }}>Send</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </DocumentMeta>
    );
}
 
export default connect()(ContactMe);