import React from 'react';
import avatar from '../assets/img/avatar.jpg';
import DocumentMeta from 'react-document-meta';

const meta = {
    title: 'About Me | Omda',
    description: 'I am Emad Aldeen Mukhtar Idris Adam Software Engineer graduate from Al-Neelain University 2016',
    canonical: 'https:/emadmukhtar.com/about-me',
    meta: {
        charset: 'utf-8',
        name: {
            keywords: 'omda,OmdaMukhtar,omda-mukhtar,Omda-Mukhtar,omda-mukhtar',
            viewport: 'initial-scale=1.0, width=device-width',
            robots: 'index,follow',
            google: 'sitelinkssearchbox'
        },
        property: {
            'og:type': 'article',
            'og:site_name': 'omdamukhtar.com',
            'twitter:site': '@omdaemad96',
            'twitter:title': 'Omda Mukhtar'
        }
    }
};

const AboutMe = () => {
    return ( 
        <DocumentMeta {...meta}>
            <div className="row mt-2 row justify-content-center">
                <div className="col-lg-4 col-md-6 col-sm-12">
                        <img className="shadow rounded"
                            src={avatar}
                            alt="avatar" width="100%" />
                </div>
                <div className="col-lg-8 text-center">
                    <h2 className="mt-3 mb-3 font-weight-bold">Full Stack Web Developer</h2>
                    <p className="text-justify text-secondary lead col-md  col-sm ">
			Dedicated Software Engineer with a passion for building robust and scalable solutions. I specialize in backend development and am currently expanding my skill set to cover DevOps practices. In my recent roles, I've actively contributed to sysadmin and DevOps tasks, gaining hands-on experience with essential tools.

Development Skills
Proficient in:

- PHP, Laravel
- JavaScript, HTML5, CSS
-  jQuery, Bootstrap, Bulma, Vue.js
- GitHub/GitLab
- Python
- Shell Script

And experience with:

- Vue.js
- Reactjs
- AngularJs

DevOps Focus

In addition to my strong development background, I am actively transitioning into a DevOps role. My recent experiences have equipped me with a solid foundation in sysadmin and DevOps tools, allowing me to bridge the gap between development and operations seamlessly. I am passionate about optimizing software delivery pipelines and ensuring the reliability of complex systems.

What Sets Me Apart

Seamless integration of coding processes, envisioning the entire web development lifecycle.
Strong foundation in traditional development, enhancing my approach to DevOps with a unique perspective.
Rapid adaptability to emerging technologies and methodologies.

Let's Connect!

Open to networking with developers, DevOps professionals, and tech enthusiasts. Excited to contribute my skills to transformative projects and explore new opportunities in the DevOps space.
                    </p>
                    <div>
                        <a href="https://github.com/OmdaMukhtar">
                            <i className="fa fa-github fa-lg mt-3 mb-3"></i>
                        </a>
                        <a href="https://web.facebook.com/omda.mukhtar">
                            <i className="fa fa-facebook fa-lg mt-3 mb-3"></i>
                        </a>
                        <a href="https://twitter.com/omdaemad96">
                            <i className="fa fa-twitter fa-lg mt-3 mb-3"></i>
                        </a>
                        <a href="https://www.linkedin.com/in/omdamukhtar">
                            <i className="fa fa-linkedin fa-lg mt-3 mb-3"></i>
                        </a>
                    </div>
                </div>
            </div>
        </DocumentMeta>
    );
}
 
export default AboutMe;
