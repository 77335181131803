export default function reducer(state = {contacts: [], error: null, isLoading: false}, action){
    switch (action.type) {
        case "FETCH_CONTACT_PENDDING":{
            return {...state, isLoading: action.payload};
        }
        case "FETCH_CONTACT_FULFILLED":{
            return {...state, contacts: action.payload.data};
        }
        case "ADD_PROJECT":{
            return {...state, contacts: state.contacts.concat(action.payload)};
        }
        case "GET_PROJECT":{
            return {...state, contacts: state.contacts.find(action.payload.id)};
        }
        case "FETCH_CONTACT_REJECTED":
            return {...state, error: action.payload};
        default:
            return state;
    }
};