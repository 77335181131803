import React from "react";
import { Route, Switch } from "react-router-dom";
import Resume from "../views/resume";
import ContactMe from "../views/contactme";
import AboutMe from '../views/aboutme';
import Projects from  '../views/projects';

const ROUTES = [
//   { path: "/login", key: "ROOT", exact: true, component: () => <h1>Log in</h1> },
  {
    path: "/",
    key: "APP",
    component: RenderRoutes,
    routes: [
      {
        path: "/",
        key: "APP_ROOT",
        exact: true,
        component: Resume,
      },
      {
        path: "/contact-me",
        key: "CONTACT_ME_PAGE",
        exact: true,
        component: ContactMe,
      },
      {
        path: "/about-me",
        key: "ABOUT_ME_PAGE",
        exact: true,
        component: AboutMe,
      },
    ],
  },
];

export default ROUTES;


/**
 * Render a route with potential sub routes
 * https://reacttraining.com/react-router/web/example/route-config
 */
function RouteWithSubRoutes(route) {
    return (
      <Route
        path={route.path}
        exact={route.exact}
        render={props => <route.component {...props} routes={route.routes} />}
      />
    );
}

/**
 * Use this component for any new section of routes (any config object that has a "routes" property
 */
export function RenderRoutes({ routes }) {
    return (
      <Switch>
        {routes.map((route, i) => {
          return <RouteWithSubRoutes key={route.key} {...route} />;
        })}
        <Route path="*" component={() => <h1>Not Found!</h1>} />
      </Switch>
    );
  }
