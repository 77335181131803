import React from 'react';
import SideInfo from '../components/resume';
import Skill from '../components/skill';
import DocumentMeta from 'react-document-meta';

const meta = {
    title: 'Resume | Omda',
    description: 'I am Emad Aldeen Mukhtar Idris Adam software developer graduate from Al-Neelain University 2016',
    canonical: 'https://emadmukhtar.com',
    meta: {
        charset: 'utf-8',
        name: {
            keywords: 'omda,OmdaMukhtar,omda-mukhtar,Omda-Mukhtar,omda-mukhtar',
            viewport: 'initial-scale=1.0, width=device-width',
            robots: 'index,follow',
            google: 'sitelinkssearchbox'
        },
        property: {
            "og:type": 'article',
            "og:site_name": 'omdamukhtar.com',
            "twitter:site": '@omdaemad96',
            "twitter:title": 'Omda Mukhtar'
        }
    }
};

const Resume = () => {
    return ( 
        <section id="resume">
            <DocumentMeta {...meta}>
                <div className="row mt-2">
                    <div className="col-lg-3 col-md-3 col-sm-4 mb-2">
                        <SideInfo />
                    </div>
                    <div className="col-lg-9 col-md-9 col-sm-8">
                        <div className="card mb-5">
                            <div className="card-body">
                                <h2>Education</h2>
                                <div className="row" style={{ borderBottom: "5px solid #8a6440" }}>
                                    <div className="col-lg-4">
                                        2011-2016
                                    </div>
                                    <div className="col-lg-8">
                                        <h4>
                                            Neelain University
                                        </h4>
                                        <p className="text-secondary">
                                            I graduated from the University of Neelain, Faculty of Computer Science-Department of Computer Science
                                        </p>
                                    </div>
                                </div>

                                <h2 className="mt-2">Experience</h2>
                                <div className="row" style={{ borderBottom: "5px solid #8a6440" }}>
                                    <div className="col-lg-4">
                                        2020-Present
                                    </div>
                                     <div className="col-lg-8">
                                        <h4>
                                            Unitra Company DevOps Engineer
                                        </h4>
                                        <p className="text-secondary">
					●Proficient in Red Hat, Ubuntu, Docker, Proxmox, Shell Scripting, and Python.
●Experience with DevOps tools such as GitHub/GitLab, Jenkins, Mattermost, Slack, Redmine, and
Prometheus, Jenkins.
●Strong understanding of sysadmin practices and principles.
●Familiarity with infrastructure as code (IaC) concepts.
●Skilled in optimizing software delivery pipelines.
●Knowledgeable about CI/CD processes and methodologies.
●Start learning cloud aws.
                                        </p>
                                    </div>
        
                                    <div className="col-lg-4">
                                        2020-present
                                    </div>
                                    <div className="col-lg-8">
                                        <h4>
                                            Unitra Company Software Engineer
                                        </h4>
                                        <p className="text-secondary">
                                            I worked for the Unitra  company as Backend web developer with PHP Laravel for building components.
                                            my Job is Fulltime. my role is analysis the requirements and building compoenents based on that. and prepare
                                            APIs for mobile as RestFull API. 
                                        </p>
                                    </div>
        
                                    <div className="col-lg-4">
                                        2020-present
                                    </div>
                                    <div className="col-lg-8">
                                        <h4>
                                            ArtTime
                                        </h4>
                                        <p className="text-secondary">
                                            I worked for the ArtTime  company as Backend web developer with PHP Laravel and Vuejs for building components.
                                            Job type is part time.
                                        </p>
                                    </div>

                                    <div className="col-lg-4">
                                        2019 -2020
                                    </div>
                                    <div className="col-lg-8">
                                        <h4>
                                            SNSO
                                        </h4>
                                        <p className="text-secondary">
                                            I worked for the Shames AlEdara (SNSO)  company as a programmer, FullStack web developer, a company specialized in resource
                                            planning management (ERP) systems  for charitable organizations in the Kingdom of Saudi Arabia.
                                        </p>
                                    </div>

                                        <div className="col-lg-4">
                                            2018-2019
                                        </div>
                                        <div className="col-lg-8">
                                            <h4>
                                                Kashta-Lab
                                            </h4>
                                            <p className="text-secondary">
                                            I worked for Dan Technology in the Kingdom of Saudi Arabia as a remote software developer in educational projects.
                                            <a href="http://www.atroha.com">atroha</a>
                                            </p>
                                        </div>

                                    <div className="col-lg-4">
                                        2017-2018
                                    </div>
                                    <div className="col-lg-8">
                                        <h4>
                                            Applicome
                                        </h4>
                                        <p className="text-secondary">
                                            I worked at Applecom as a software developer using dotnet, which is a company that works in the field of accounting
                                            systems specifically for travel agencies.
                                            <a href="http://www.tabos.net">tabos</a>
                                        </p>
                                    </div>
                                </div>

                                <Skill />
                            </div>
                        </div>
                    </div>
                </div>
            </DocumentMeta>
        </section>
    );
}
 
export default Resume;
