import React from 'react';
import avatar from '../../assets/img/avatar.jpg';
import { saveAs } from 'file-saver';

const SideInfo = () => {
  const cvFilePath = '../../assets/cv-16-05-2023.pdf'; 

  const handleDownload = () => {
    saveAs(cvFilePath, 'emad_cv.pdf'); 
  };

    return ( 
        <div className="card text-center">
            <div>
                <img  className="shadow" src={avatar} alt="avatar" width="100%" />
                <h5 className="card-title mt-2">Emad Aldeen Mukhtar</h5>
            </div>

            <div className="card-body text-left title-card-top">
                <h6 className="card-title  font-weight-bold">Software Engineer | DevOps Engineer</h6>
                <p className="card-text text-secondary">
		Dedicated Software Engineer with a passion for building robust and scalable solutions. I specialize in backend development and am currently expanding my skill set to cover DevOps practices. In my recent roles, I've actively contributed to sysadmin and DevOps tasks, gaining hands-on experience with essential tools.
                </p>
            </div>

            <div className="card-body text-left">
                <h6 className="card-title  font-weight-bold">Address</h6>
                <p className="card-text text-secondary">Dubai, United Arab Emirates</p>
            </div>

            <div className="card-body text-left">
                <h6 className="card-title  font-weight-bold">Phone</h6>
                <p className="card-text text-secondary">+971554556031</p>
            </div>

            <div className="card-body text-left">
                <h6 className="card-title  font-weight-bold">Email</h6>
                <p className="card-text text-secondary">abuoop123@gmail.com</p>
            </div>

             <div className="card-body text-left title-card">
                <h6 className="card-title  font-weight-bold">Web</h6>
                <p className="card-text text-secondary">
                    <a href="https://emadmukhtar.com">www.emadmukhtar.com</a>
                </p>
            </div>

           <div className="card-body text-left title-card">
                <button onClick={handleDownload}>Download CV</button>
	    </div>

        </div>
    );
}
 
export default SideInfo;
