export default function reducer(state = {projects: [], error: null}, action){
    switch (action.type) {
        case "FETCH_PROJECT_PENDDING":{
            return {...state, projects: action.payload};
        }
        case "FETCH_PROJECT_FULFILLED":{
            return {...state, projects: action.payload.data};
        }
        case "ADD_PROJECT":{
            return {...state, projects: state.projects.concat(action.payload)};
        }
        case "GET_PROJECT":{
            return {...state, projects: state.projects.find(action.payload.id)};
        }
        case "FETCH_PROJECT_REJECTED":
            return {...state, error: action.payload};
        default:
            return state;
    }
};