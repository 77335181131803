import sendEmailService from '../../services/contact.service';

import {
    FETCH_CONTACT_PENDDING,
    FETCH_CONTACT_REJECTED,
    FETCH_CONTACT_FULFILLED
} from './types';

export function sendEmail(subject, email, message){
    
    return function(dispatch){
        dispatch({type: FETCH_CONTACT_PENDDING, payload: true});
        sendEmailService(subject, email, message)
        .then(response =>{
            console.log("response from server = ", response);
            if(response.status === 200){
                window.flash(response.data.message, 'success');
            }
            dispatch({type:FETCH_CONTACT_FULFILLED, payload:response.data});
        })
        .catch(error =>{
            console.log(error);
            dispatch({type:FETCH_CONTACT_REJECTED, payload:error});
        });
        // axios.get('http://localhost:8000/sanctum/csrf-cookie').then(response => {
        //     if(response.status == 204){
        //         return axios.post('http://localhost:8000/api/contacts/send-email', {
        //             subject,
        //             email,
        //             message
        //         })
        //         .then(response =>{
        //             console.log(response);
        //             if(response.status == 200){
        //                 window.flash(response.data.message, 'success');
        //             }
        //             dispatch({type:FETCH_CONTACT_FULFILLED, payload:response.data});
        //         })
        //         .catch(error =>{
        //             console.log(error);
        //             dispatch({type:FETCH_CONTACT_REJECTED, payload:error});
        //         });
        //     }
        // });
    }
}
