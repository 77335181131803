import httpClient from './api';

httpClient.defaults.withCredentials = true;

// const initSPAConnection = ()=> {
//     httpClient.get('/sanctum/csrf-cookie')
//     .then(response=> console.log('from contact service', response))
//     .catch(error => console.log(error));
// }

const sendEmailService = (subject, email, message)=>{
    // register spa csrf
    // initSPAConnection();
    return httpClient.get('/sanctum/csrf-cookie')
    .then((response)=> {
        // send an request to the end point
        console.log('from contact service', response)
        return httpClient.post('/api/contacts/send-email', {subject, email, message});
    })
    .catch(error => console.log(error));

    
}

export default sendEmailService;
