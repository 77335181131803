import React from 'react';
import { NavLink } from 'react-router-dom';

const NavBar = () => {
    return ( 
          <nav className="navbar navbar-fixed-top bg-info ">
            <div>
                  <button className="navbar-toggler pull-xs-left hidden-md-up" type="button" data-toggle="collapse"
                      data-target="#navbar-collapse">&#9776;</button>
                  <NavLink  className="navbar-brand nav-link font-weight-bold"  to="/">Resume</NavLink>
            </div>

              <div className=" navbar-toggleable-sm" id="navbar-collapse">
                  <ul className="nav  ">
                      <li className="nav-item" >
                          <NavLink className="nav-link" activeClassName="active" to="/">Resume</NavLink>
                      </li>
                      <li className="nav-item" >
                          <NavLink className="nav-link" activeClassName="active" to="about-me">About Me</NavLink>
                      </li>
                      <li className="nav-item" >
                          <NavLink className="nav-link" activeClassName="active" to="contact-me">Contact</NavLink>
                      </li>
                  </ul>
              </div>
          </nav>
    );
}
 
export default NavBar;
