import React from 'react';

const Skill = () => {
    return (
        <div>
            <h2 className="mt-2">Skills</h2>
            <div className="row ml-1">
                <div className="col-lg-12">
                     <label className="mr-5">Redhat RHEL9</label>
                    <div className="progress">
                        <div className="progress-bar bg-success" role="progressbar" style={{ width: "100" }}
                            aria-valuenow="75" aria-valuemin="0" aria-valuemax="0">
                        </div>
                    </div>
                    <label className="mr-5">Docker</label>
                    <div className="progress">
                        <div className="progress-bar bg-success" role="progressbar" style={{ width: "100" }}
                            aria-valuenow="75" aria-valuemin="0" aria-valuemax="0">
                        </div>
                    </div>
                    <label className="mr-5">Aws cloud</label>
                    <div className="progress">
                        <div className="progress-bar bg-success" role="progressbar" style={{ width: "100" }}
                            aria-valuenow="75" aria-valuemin="0" aria-valuemax="0">
                        </div>
                    </div>
                    <label className="mr-5">Python Flask</label>
                    <div className="progress">
                        <div className="progress-bar bg-success" role="progressbar" style={{ width: "100" }}
                            aria-valuenow="75" aria-valuemin="0" aria-valuemax="0">
                        </div>
                    </div>

                   <label className="mr-5">PHP</label>
                    <div className="progress">
                        <div className="progress-bar bg-success" role="progressbar" style={{ width: "100" }}
                            aria-valuenow="75" aria-valuemin="0" aria-valuemax="0">
                        </div>
                    </div>

                    <label className="mr-5">Laravel</label>
                    <div className="progress">
                        <div className="progress-bar bg-success" role="progressbar" style={{ width: "100" }}
                            aria-valuenow="75" aria-valuemin="0" aria-valuemax="0">
                        </div>
                    </div>
 
                    <label className="mr-5">Digital Ocean Cloud</label>
                    <div className="progress">
                        <div className="progress-bar bg-success" role="progressbar" style={{ width: "100" }}
                            aria-valuenow="75" aria-valuemin="0" aria-valuemax="0">
                        </div>
                    </div>
 
                    <label className="mr-5">Jenkins</label>
                    <div className="progress">
                        <div className="progress-bar bg-success" role="progressbar" style={{ width: "100" }}
                            aria-valuenow="75" aria-valuemin="0" aria-valuemax="0">
                        </div>
                    </div>
 
                    <label className="mr-5">ChatOps</label>
                    <div className="progress">
                        <div className="progress-bar bg-success" role="progressbar" style={{ width: "100" }}
                            aria-valuenow="75" aria-valuemin="0" aria-valuemax="0">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Skill;
