import React from 'react';
import { connect } from 'react-redux';
import fullhunt from '../assets/img/fullhunt.png';
import meshkat from '../assets/img/meshkat.png';
import sms from '../assets/img/sms.png';
import couponer from '../assets/img/couponer/couponer.png';
import resumeImage from '../assets/img/portfolio/resume.png';
import Modal from 'react-modal';
import ImageGallery from 'react-image-gallery';
import "../../node_modules/react-image-gallery/styles/css/image-gallery.css";
import fullhunt1 from '../assets/img/fullhunt/f1.png';
import fullhunt2 from '../assets/img/fullhunt/f2.png';
import fullhunt3 from '../assets/img/fullhunt/f3.png';
import fullhunt4 from '../assets/img/fullhunt/f4.png';
import fullhunt5 from '../assets/img/fullhunt/f5.png';

import sms1 from '../assets/img/sms/login.png';
import sms2 from '../assets/img/sms/dashboard.png';
import sms3 from '../assets/img/sms/songs.png';
import sms4 from '../assets/img/sms/companies.png';
import sms5 from '../assets/img/sms/companies_add.png';
import sms6 from '../assets/img/sms/profile.png';
import sms7 from '../assets/img/sms/home.png';
import sms8 from '../assets/img/sms/url_song.png';

import meshkat1 from '../assets/img/meshkat/login.png';
import meshkat2 from '../assets/img/meshkat/dashboard.png';
import meshkat3 from '../assets/img/meshkat/dashboard_consultant.png';
import meshkat4 from '../assets/img/meshkat/dashboard_fatawi.png';
import meshkat5 from '../assets/img/meshkat/fatawi.png';
import meshkat6 from '../assets/img/meshkat/profile.png';
import meshkat7 from '../assets/img/meshkat/home.png';
import meshkat8 from '../assets/img/meshkat/library.png';
import meshkat9 from '../assets/img/meshkat/search.png';

import DocumentMeta from 'react-document-meta';

const meta = {
    title: 'Projects | Omda',
    description: 'I am Emad Aldeen Mukhtar Idris Adam software developer graduate from Al-Neelain University 2016',
    canonical: 'http://omdamukhtar.com/projects',
    meta: {
        charset: 'utf-8',
        name: {
            keywords: 'omda,OmdaMukhtar,omda-mukhtar,Omda-Mukhtar,omda-mukhtar',
            viewport: 'initial-scale=1.0, width=device-width',
            robots: 'index,follow',
            google: 'sitelinkssearchbox'
        },
        property: {
            'og:type': 'article',
            'og:site_name': 'omdamukhtar.com',
            'twitter:site': '@omdaemad96',
            'twitter:title': 'Omda Mukhtar'
        }
    }
};


const fullHuntImages = [
  {
    original:fullhunt1,
    thumbnail: fullhunt1,
  },
    {
    original:fullhunt5,
    thumbnail: fullhunt5,
  },
  {
    original:fullhunt2,
    thumbnail: fullhunt2,
  },
  {
    original:fullhunt3,
    thumbnail: fullhunt3,
  },
   {
    original:fullhunt4,
    thumbnail: fullhunt4,
  },
];

const smsImages = [
  {
    original:sms1,
    thumbnail: sms1,
  },
    {
    original:sms2,
    thumbnail: sms2,
  },
  {
    original:sms3,
    thumbnail: sms3,
  },
  {
    original:sms4,
    thumbnail: sms4,
  },
   {
    original:sms5,
    thumbnail: sms5,
  },
  {
    original:sms6,
    thumbnail: sms6,
  },
  {
    original:sms7,
    thumbnail: sms7,
  },
  {
    original:sms8,
    thumbnail: sms8,
  },
];

const meshkatImages = [
  {
    original:meshkat1,
    thumbnail: meshkat1,
  },
    {
    original:meshkat2,
    thumbnail: meshkat2,
  },
  {
    original:meshkat3,
    thumbnail: meshkat3,
  },
  {
    original:meshkat4,
    thumbnail: meshkat4,
  },
   {
    original:meshkat5,
    thumbnail: meshkat5,
  },
  {
    original:meshkat6,
    thumbnail: meshkat6,
  },
  {
    original:meshkat7,
    thumbnail: meshkat7,
  },
   {
    original:meshkat8,
    thumbnail: meshkat8,
  },
   {
    original:meshkat9,
    thumbnail: meshkat9,
  },
];

const MyGallery = ({images}) =>{
    return <ImageGallery 
        items={images} />
}
const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
  }
};

Modal.setAppElement('#root')
 
const MDModal = ({imagesItem})=>{
  const [modalIsOpen,setIsOpen] = React.useState(false);
  function openModal() {
    setIsOpen(true);
  }
 
  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = '#000';
  }
 
  function closeModal(){
    setIsOpen(false);
  }
 
    return (
      <div className="d-inline">
          <button className="mb-1 mr-1 btn bg-info text-light btn-sm " onClick={openModal}>Preview</button>
        <Modal
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
 
          {/* <h2 ref={_subtitle => (subtitle = _subtitle)}></h2> */}
          <button onClick={closeModal} className="btn btn-default">close</button>
          <div style={{ border:'1px solid #CCC', borderRadius:'2px', height:'10%'}}>
          <MyGallery  images={imagesItem}/>
          </div>
        </Modal>
      </div>
    );
}

const Projects = (props) => {
    return ( 
        <DocumentMeta {...meta}>
            <div>
                <ul className="nav nav-tabs justify-content-center" id="myTab" role="tablist" >
                    <li className="nav-item">
                        <a className="nav-link " id="home-tab" data-toggle="tab" href="#home" role="tab"
                            aria-controls="home" aria-selected="false">Laravel</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" id="vue-tab" data-toggle="tab" href="#vuejs" role="tab"
                            aria-controls="vuejs" aria-selected="false">VueJs</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" id="contact-tab" data-toggle="tab" href="#contact" role="tab"
                            aria-controls="contact" aria-selected="false">NodeJs</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" id="react-tab" data-toggle="tab" href="#react" role="tab"
                            aria-controls="contact" aria-selected="false">Reactjs</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link active" id="all-tab" data-toggle="tab" href="#all" role="tab"
                            aria-controls="all" aria-selected="true">All</a>
                    </li>
                </ul>
                <div className="tab-content  justify-content-center" id="myTabContent">
                    <div className="tab-pane fade show " id="home" role="tabpanel" aria-labelledby="home-tab">
                        <div className="row mt-2">
                            <div className="col-lg-4 col-md-6 col-sm-8 mb-3 ">
                                <div className="">
                                    <i className="fa fa-share-alt position-absolute text-light p-2 fa-lg" ></i>
                                    <img alt="img" src={sms}
                                    width="100%" />
                                </div>
                                <div className="card-body my-image">
                                    <h5 className="card-title">
                                        <a href="http://sms.omdamukhtar.com/login" >SMS APP</a>
                                    </h5>
                                    <p className="card-text">This is a longer card with supporting text below as a natural
                                        lead-in to additional content. This content is a little bit longer.</p>
                                        <footer className="footer text-center">
                                        <MDModal imagesItem={smsImages} />
                                        {/* <button className="mb-1 mr-1 btn bg-info text-light btn-sm ">Preview</button> */}
                                        <button className="mb-1 mr-1 btn bg-info text-light btn-sm ">Description</button>
                                        <button className="mb-1 mr-1 btn bg-info text-light btn-sm ">GitHub</button>
                                    </footer>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-8 mb-3 ">
                                <div className="">
                                    <i className="fa fa-share-alt position-absolute text-light p-2 fa-lg"></i>
                                    <img alt="img" src={meshkat} width="100%" />
                                </div>
                                <div className="card-body my-image">
                                    <h5 className="card-title"> <a href="http://meshkat.omdamukhtar.com">
                                            Meshkat
                                        </a></h5>
                                    <p className="card-text">This is a longer card with supporting text below as a natural
                                        lead-in to additional content. This content is a little bit longer.</p>
                                    <footer className="footer text-center">
                                        <MDModal imagesItem={meshkatImages} />
                                        <button className="mb-1 mr-1 btn bg-info text-light btn-sm ">Description</button>
                                        <button className="mb-1 mr-1 btn bg-info text-light btn-sm ">GitHub</button>
                                    </footer>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-8 mb-3 ">
                                <div className="">
                                    <i className="fa fa-share-alt position-absolute text-light p-2 fa-lg"></i>
                                    <img alt="img" src="https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcQwBPxulY5v0iCYquZ06giVxEkbBDlRUh7RaMlPjtf6qF179BBC"
                                        width="100%" />
                                </div>
                                <div className="card-body my-image">
                                    <h5 className="card-title">Resturent</h5>
                                    <p className="card-text">This is a longer card with supporting text below as a natural
                                        lead-in to additional content. This content is a little bit longer.</p>
                                    <footer className="footer text-center">
                                        <button className="mb-1 mr-1 btn bg-info text-light btn-sm ">Preview</button>
                                        <button className="mb-1 mr-1 btn bg-info text-light btn-sm ">Description</button>
                                        <button className="mb-1 mr-1 btn bg-info text-light btn-sm ">GitHub</button>
                                    </footer>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane fade" id="vuejs" role="tabpanel" aria-labelledby="vue-tab">
                        <div className="row mt-2">
                            <div className="col-lg-4">
                                <i className="fa fa-share-alt position-absolute text-light p-2 fa-lg"></i>
                                <img alt="img" src={fullhunt} width="100%" />
                                <div className="card-body my-image ">
                                    <h5 className="card-title"><a href="https://portal.fullhunt.io/">FullHunt</a></h5>
                                    <p className="card-text">This is a longer card with supporting text below as a natural
                                        lead-in to additional content. This content is a little bit longer.</p>
                                    <footer className="footer text-center">
                                        <MDModal imagesItem={fullHuntImages} />
                                        {/* <button className="mb-1 mr-1 btn bg-info text-light btn-sm ">Preview</button> */}
                                        <button className="mb-1 mr-1 btn bg-info text-light btn-sm ">Description</button>
                                        <button className="mb-1 mr-1 btn bg-info text-light btn-sm ">GitHub</button>
                                    </footer>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-8 mb-3">
                                <i className="fa fa-share-alt position-absolute text-light p-2 fa-lg"></i>
                                <img alt="img" src={couponer} width="100%" />
                                <div className="card-body my-image ">
                                    <h5 className="card-title"><a href="https://couponr.shop/">Couponer</a></h5>
                                    <p className="card-text">It's the application of coupons just display available coupons to the page and then let the user use one of them
                                        and the tools which we use is firebase Realtime database and Vuejs as frontend</p>
                                    <footer className="footer text-center">
                                        {/* <MDModal imagesItem={couponer} /> */}
                                        <button className="mb-1 mr-1 btn bg-info text-light btn-sm ">Preview</button>
                                        <button className="mb-1 mr-1 btn bg-info text-light btn-sm ">Description</button>
                                        <button className="mb-1 mr-1 btn bg-info text-light btn-sm ">GitHub</button>
                                    </footer>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
                        <div className="mt-2 col-lg-4 col-md-6 col-sm-8 mb-3 ">
                            <div className="">
                                <i className="fa fa-share-alt position-absolute text-light p-2 fa-lg"></i>
                                <img alt="img" src="https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcQwBPxulY5v0iCYquZ06giVxEkbBDlRUh7RaMlPjtf6qF179BBC"
                                    width="100%" />
                            </div>
                            <div className="card-body my-image">
                                <h5 className="card-title">RestApi Nodejs With Mysql</h5>
                                <p className="card-text">This is a longer card with supporting text below as a natural
                                    lead-in to additional content. This content is a little bit longer.</p>
                                <footer className="footer text-center">
                                    <button className="mb-1 mr-1 btn bg-info text-light btn-sm ">Preview</button>
                                    <button className="mb-1 mr-1 btn bg-info text-light btn-sm ">Description</button>
                                    <button className="mb-1 mr-1 btn bg-info text-light btn-sm ">GitHub</button>
                                </footer>
                            </div>
                        </div>
                    </div>
                    {/* Begin React Tab */}
                    <div className="tab-pane fade" id="react" role="tabpanel" aria-labelledby="react-tab">
                        <div className="mt-2 col-lg-4 col-md-6 col-sm-8 mb-3 ">
                            <div className="" >
                                <i className="fa fa-share-alt position-absolute text-light p-2 fa-lg"></i>
                                <img alt="img" src={resumeImage}
                                    width="100%" />
                            </div>
                            <div className="card-body my-image">
                                <h5 className="card-title">
                                    <a href="http://omdamukhtar.com" >My Portfolio</a>
                                </h5>
                                <p className="card-text">it's my portfolio page  build with Reactjs and the Backend is Laravel Framework</p>
                                <footer className="footer text-center">
                                    <button className="mb-1 mr-1 btn bg-info text-light btn-sm ">Preview</button>
                                    <button className="mb-1 mr-1 btn bg-info text-light btn-sm ">Description</button>
                                    <button className="mb-1 mr-1 btn bg-info text-light btn-sm ">GitHub</button>
                                </footer>
                            </div>
                        </div>

                        
                    </div>
                    {/* End React tap */}
                    <div className="tab-pane fade active show" id="all" role="tabpanel" aria-labelledby="all-tab">
                        <div className="row mt-2">
                            <div className="col-lg-4 col-md-6 col-sm-8 mb-3 ">
                                <div className="">
                                    <i className="fa fa-share-alt position-absolute text-light p-2 fa-lg"></i>
                                    <img alt="img" src={sms}
                                        width="100%" />
                                </div>
                                <div className="card-body my-image">
                                    <h5 className="card-title">
                                        <a href="http://sms.omdamukhtar.com/login" >SMS APP</a>
                                    </h5>
                                    <p className="card-text">This is a longer card with supporting text below as a natural
                                        lead-in to additional content. This content is a little bit longer.</p>
                                    <footer className="footer text-center">
                                        <MDModal imagesItem={smsImages} />
                                        {/* <button className="mb-1 mr-1 btn bg-info text-light btn-sm " onClick={openModal}>Preview</button> */}
                                        <button className="mb-1 mr-1 btn bg-info text-light btn-sm ">Description</button>
                                        <button className="mb-1 mr-1 btn bg-info text-light btn-sm ">GitHub</button>
                                    </footer>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-8 mb-3 ">
                                <div className="">
                                    <i className="fa fa-share-alt position-absolute text-light p-2 fa-lg"></i>
                                    <img alt="img" src={meshkat} width="100%" />
                                </div>
                                <div className="card-body my-image">
                                    <h5 className="card-title">
                                    <a href="http://meshkat.omdamukhtar.com">
                                        Meshkat
                                    </a>
                                </h5>
                                    <p className="card-text">This is a longer card with supporting text below as a natural
                                        lead-in to additional content. This content is a little bit longer.</p>
                                    <footer className="footer text-center">
                                        <MDModal imagesItem={meshkatImages} />
                                        <button className="mb-1 mr-1 btn bg-info text-light btn-sm ">Description</button>
                                        <button className="mb-1 mr-1 btn bg-info text-light btn-sm ">GitHub</button>
                                    </footer>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-8 mb-3 ">
                                <div className="">
                                    <i className="fa fa-share-alt position-absolute text-light p-2 fa-lg"></i>
                                    <img alt="img" src="https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcQwBPxulY5v0iCYquZ06giVxEkbBDlRUh7RaMlPjtf6qF179BBC"
                                        width="100%" />
                                </div>
                                <div className="card-body my-image">
                                    <h5 className="card-title">Resturent</h5>
                                    <p className="card-text">This is a longer card with supporting text below as a natural
                                        lead-in to additional content. This content is a little bit longer.</p>
                                    <footer className="footer text-center">
                                        <button className="mb-1 mr-1 btn bg-info text-light btn-sm ">Preview</button>
                                        <button className="mb-1 mr-1 btn bg-info text-light btn-sm ">Description</button>
                                        <button className="mb-1 mr-1 btn bg-info text-light btn-sm ">GitHub</button>
                                    </footer>
                                </div>
                            </div>
                            <div className=" col-lg-4">
                                <i className="fa fa-share-alt position-absolute text-light p-2 fa-lg"></i>
                                <img alt="img" src={fullhunt} width="100%" />
                                <div className="card-body my-image ">
                                    <h5 className="card-title"><a href="https://portal.fullhunt.io/">FullHunt</a></h5>
                                    <p className="card-text">This is a longer card with supporting text below as a natural
                                        lead-in to additional content. This content is a little bit longer.</p>
                                    <footer className="footer text-center">
                                        <MDModal imagesItem={fullHuntImages} />
                                        {/* <button className="mb-1 mr-1 btn bg-info text-light btn-sm ">Preview</button> */}
                                        <button className="mb-1 mr-1 btn bg-info text-light btn-sm ">Description</button>
                                        <button className="mb-1 mr-1 btn bg-info text-light btn-sm ">GitHub</button>
                                    </footer>
                                </div>
                            </div>
                            <div className=" col-lg-4">
                                <i className="fa fa-share-alt position-absolute text-light p-2 fa-lg"></i>
                                <img alt="img" src={couponer} width="100%" />
                                <div className="card-body my-image ">
                                    <h5 className="card-title"><a href="https://couponr.shop/">Couponer</a></h5>
                                    <p className="card-text">It's the application of coupons just display available coupons to the page and then let the user use one of them
                                        and the tools which we use is firebase Realtime database and Vuejs as frontend</p>
                                    <footer className="footer text-center">
                                        {/* <MDModal imagesItem={couponer} /> */}
                                        <button className="mb-1 mr-1 btn bg-info text-light btn-sm ">Preview</button>
                                        <button className="mb-1 mr-1 btn bg-info text-light btn-sm ">Description</button>
                                        <button className="mb-1 mr-1 btn bg-info text-light btn-sm ">GitHub</button>
                                    </footer>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="" >
                                    <i className="fa fa-share-alt position-absolute text-light p-2 fa-lg"></i>
                                    <img alt="img" src={resumeImage}
                                        width="100%" height="100%"/>
                                </div>
                                <div className="card-body my-image">
                                    <h5 className="card-title">
                                        <a href="http://omdamukhtar.com" >My Portfolio</a>
                                    </h5>
                                    <p className="card-text">it's my portfolio page  build with Reactjs and the Backend is Laravel Framework</p>
                                    <footer className="footer text-center">
                                        <button className="mb-1 mr-1 btn bg-info text-light btn-sm ">Preview</button>
                                        <button className="mb-1 mr-1 btn bg-info text-light btn-sm ">Description</button>
                                        <button className="mb-1 mr-1 btn bg-info text-light btn-sm ">GitHub</button>
                                    </footer>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </DocumentMeta>
    );
}
 
const mapStateToProps = (state) =>{
    return {
        projects: state.projectReducer.projects
    };
};

export default connect(mapStateToProps)(Projects);