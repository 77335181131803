import React from 'react';
import Navbar from '../components/partials/navbar';
import '../assets/css/style.css';
import ROUTES, { RenderRoutes } from "../router";
import { createBrowserHistory } from 'history'
import { BrowserRouter as Router } from "react-router-dom";
import {Provider} from 'react-redux';
import store from '../store';
import FlashMessage from '../components/general/flashMessage';
import Bus from '../utils/bus';
import TagManager from 'react-gtm-module'

window.flash = (message, type="success") => Bus.emit('flash', ({message, type}));


const tagManagerArgs = {
    gtmId: 'GTM-5D2KFBX'
}

TagManager.initialize(tagManagerArgs)

const App = () => {
    return (
        <Provider store={store}>
            <Router history={createBrowserHistory}>
                <div>
                    <Navbar />
                    <div className="container">
                        <FlashMessage />
                        <RenderRoutes routes={ROUTES} />
                    </div>
                </div>
            </Router>
        </Provider>
    )
}

export default App;