import React, { useEffect, useState } from 'react';
import Bus from '../../utils/bus';
import './flashMessage.css';

/**
 * Message Types 
 * 
 * @type primary
 * @type secondary
 * @type success
 * @type danger
 * @type warning
 * @type info
 * @type light
 * @type dark
 */

const FlashMessage = () => {
    let [visibility, setVisibility] = useState(false);
    let [message, setMessage] = useState('');
    let [type, setType] = useState('');

    useEffect(() => {
        Bus.addListener('flash', ({message, type}) => {
            
            setVisibility(true);
            setMessage(message);
            setType(type);
            setTimeout(() => {
                setVisibility(false);
            }, 5000);
        });
                

    }, []);

    useEffect(() => {
        if(document.querySelector('.close') !== null) {
            document.querySelector('.close').addEventListener('click', () => setVisibility(false));
        }
    })

    return (
        visibility && <div className={`mt-2 alert alert-${type} alert-dismissible fade show msg-dir`} role="alert">
            {message}
            <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
    )
}
 
export default FlashMessage;