import axios from "axios";
import NProgress from '../../node_modules/nprogress/nprogress';

const httpClient = axios.create({
    withCredentials: true,
    baseURL: process.env.REACT_APP_BASE_URL,
    // timeout: 1000,
    headers: {
        "Content-Type": "application/json",
    }
});

console.log(process.env);
// function setHeader(getAuthToken){
//     const authInterceptor = (config) => {
//         config.headers['X-CSRF-TOKEN'] = getAuthToken;
//         return config;
//     }
    
//     httpClient.interceptors.request.use(authInterceptor);
// }

// before a request is made start the nprogress
httpClient.interceptors.request.use(config => {

    // if(TokenService.getToken()){
    //   config.headers['X-CSRF-TOKEN'] = TokenService.getToken();
    // }
    
    NProgress.start();

    return config;
});

  
// before a response is returned stop nprogress
httpClient.interceptors.response.use(response => {
    NProgress.done();
    return response;
},
error => {

  NProgress.done();
  return error.response;
});




export default httpClient;